import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {Helmet} from "react-helmet";
import '../styles/index.css';
import Typing from 'react-typing-animation';


function IndexPage() {
  return (
    <Layout>
    <SEO
      title="Portfolio" 
      keywords={[`Hashir`, `Ghouri`, `Portfolio`, `Home`]}
    />
   <Helmet>
      <link rel="stylesheet" href="https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css"/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.compat.css"/>
    </Helmet>
    
    <div className="flex flex-wrap">
        <div className="box w-full text-center animated fadeIn">
          <h1>Hello</h1>
          <h1>World</h1>
        </div>
    </div>

         <div className="flex flex-wrap text-center">
          <div className="w-full aa ">
             <Typing speed={30}>
             <Typing.Delay ms={800} />
                <span className="hashir ">I&apos;m  </span>
                <span className="h">HASHIR.</span>
              </Typing>
         </div>
          <div className="w-full bb">
           <Typing>
              <Typing.Delay ms={3500} />
              <span className="shade" data-shadow='MERN'>MERN </span> 
              <span>Stakc</span>
              <Typing.Backspace count={3} />
              <span className="">ack Developerr</span>
              <Typing.Backspace count={1} />
               <span className="">!</span>
            </Typing>
              <hr className="style14" />
            </div>

            
            
      </div>
      <div className="w-full pt-9 pb-3">
              <div className="letter-changer">
              </div>
            </div>

        <div className="flex content-center wrp">
              <h1>
                <span>Do<hide>n&apos;t</hide></span>
                <span><hide>Qu</hide>it</span>
              </h1>
        </div>

        <div className="w-full resume">
        <h6 className='content-center'>
          Download my <a href='https://drive.google.com/file/d/18ZgwAEPUI4Y5zlFhUiHtxf8isTkpk_JQ/view?usp=sharing' className='a-tag'>Resume.</a> 
        </h6>
        </div>
    
     
  </Layout>
  );
}

export default IndexPage;
